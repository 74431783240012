import React from 'react';

const PG = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="61"
    height="29"
    viewBox="0 0 61 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4915 0.164062H7.76031C7.76031 0.164062 8.32234 0.66025 8.39199 1.04588C8.49727 1.63169 8.23358 2.21423 8.23358 2.21423L1.58439 23.7001C1.58439 23.7001 1.28701 24.4783 0.914159 24.8256C0.612895 25.1066 0 25.3506 0 25.3506H9.3379C9.3379 25.3506 8.72241 25.031 8.54975 24.6582C8.39685 24.3278 8.48529 23.7273 8.48529 23.7273L10.7937 15.7156C10.7937 15.7156 21.7934 17.3504 25.128 9.0369C27.9285 2.05756 22.4338 0.164062 20.4915 0.164062ZM19.3227 8.61136C17.0755 15.2682 11.2971 14.1005 11.2971 14.1005L14.9408 2.11055H17.0616C17.0619 2.11055 21.6428 1.738 19.3227 8.61136Z"
      fill="#475467"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.9439 10.0554C47.6248 10.597 42.6549 12.451 42.6549 12.451L42.0747 15.0117C42.0747 15.0117 43.8078 14.1901 44.9782 13.8473C46.0064 13.5467 47.6698 13.303 47.6698 13.303L44.87 23.4878C44.87 23.4878 37.008 25.2446 41.3371 11.7059C42.295 8.7091 45.5749 3.85221 48.8826 2.75059C52.5979 1.51323 56.434 4.34807 56.434 4.34807L57.5412 0.993817C57.5412 0.993817 53.3909 -0.105843 50.6973 0.00830992C45.3715 0.233999 41.8739 1.65682 38.1673 5.5246C33.4696 10.4283 33.8949 17.7059 33.8949 17.7059L32.1012 19.1202L30.3426 20.5071C30.3426 20.5071 29.2169 18.7676 28.8217 17.5198C28.5026 16.5137 28.3497 14.8527 28.3497 14.8527C28.3497 14.8527 31.3546 13.3377 32.89 12.0787C35.9477 9.57097 34.8437 6.64258 32.8071 6.34493C30.4537 6.00084 29.3005 6.15816 27.5677 7.54828C24.914 9.6776 24.8113 14.1551 24.8113 14.1551C24.8113 14.1551 16.1002 16.2896 17.1971 22.6537C17.5214 24.5364 20.3403 25.6613 22.6441 25.6184C26.235 25.4581 28.6837 23.8329 28.6837 23.8329C28.6837 23.8329 30.8972 26.9215 36.4275 28.2855C43.5516 30.0422 50.6841 26.4162 50.6841 26.4162L51.1023 25.2982C51.1023 25.2982 45.8243 28.1259 39.8321 26.9732C34.2668 25.9033 31.3552 22.205 31.3552 22.205C31.3552 22.205 32.3371 21.4429 32.9445 20.93C33.4874 20.4714 34.3099 19.7227 34.3099 19.7227C34.3099 19.7227 35.3798 23.0901 38.2768 24.4609C44.6588 27.4812 51.5768 23.9676 51.5768 23.9676L54.9575 12.6642C54.9575 12.6642 56.3708 12.5281 57.2769 12.5331C58.2688 12.538 59.814 12.7175 59.814 12.7175L60.6047 9.73026C60.6044 9.73026 54.6442 9.45126 50.9439 10.0554ZM24.2311 23.2749C22.8602 23.3619 21.3552 22.795 21.0092 21.5128C20.0597 17.9934 25.0219 16.2366 25.0219 16.2366C25.0219 16.2366 25.3863 18.2194 25.8567 19.4077C26.3218 20.5833 27.3947 22.2576 27.3947 22.2576C27.3947 22.2576 25.8758 23.1715 24.2311 23.2749ZM31.5654 7.92017C32.5013 8.05886 32.2564 10.162 30.9713 11.1024C29.9691 12.1785 28.2438 12.6639 28.2438 12.6639C28.2438 12.6639 28.0319 7.39684 31.5654 7.92017Z"
      fill="#475467"
    />
  </svg>
);

export default PG;
